<template>
    <div>
        <!-- <h2>It’s <span class="green-text">PICKL</span> Time, What do you want?</h2> -->
        <h2 style="margin-bottom:40px;">Bam! It’s&nbsp;<span class="green-text">PICKL</span>&nbsp;Time. GOOOOO {{contactPersonName}}!</h2>
          <div class="col-xs-12 col-sm-4">
                <a href="javascript:;" data-toggle="modal" data-target="#choose-pickl-type-popup"><Card icon="Dashboard/create_pickl.png" value="Build Campaign"  color="#782b54" /></a>
            </div>
            <div class="col-xs-12 col-sm-4">
                <router-link to="/brand/payment-history?tab=subscription"><Card icon="Dashboard/brandpayment_icon.png" :value="'Credits: ' + dashboardContent.credits"   color="#d36b00" /></router-link>
            </div>
            <!-- <div class="col-xs-12 col-sm-4" v-else>
                <router-link to="/brand/insights"><Card icon="Dashboard/brandpayment_icon.png" value="Consumer Insights" :caption="'Insight Feature Not AVailable For You'" color="#d36b00" /></router-link>
            </div> -->
            <div class="col-xs-12 col-sm-4">
                <router-link to="/brand/tutorial-videos"><Card icon="Dashboard/viewhostory_icon.png" value="Tutorials" color="#cb4f40" /></router-link>
            </div>

        <div class="col-sm-4 col-xs-12" >
          <h3 style="margin-bottom: 20px;margin-left:15px;font-size:150%;">Geo-Located Activities</h3>
          <router-link to="/brand/pickl-history?status=APPROVED"><Card icon="Dashboard/newpickls_icon.png" value="Fresh Alerts"  color="#12db6d" /></router-link>
          <a data-target="#export-pickl-popup"  data-toggle="modal" style="cursor: pointer;"><Card icon="Dashboard/create_pickl.png" value="Data Export"  color="#C81C67" /></a>

        </div>
        <div class="col-sm-4 col-xs-12" >
          <h3 style="margin-bottom: 20px;margin-left:15px;font-size:150%;">Go-Anywhere Activities</h3>
          <router-link to="/brand/non-geo-pickl-history?status=APPROVED"><Card icon="Dashboard/newpickls_icon.png" value="Fresh Alerts"  color="#12db6d" /></router-link>
          <router-link to="/brand/non-geo-pickl-history?status=APPROVED"><Card icon="Dashboard/create_pickl.png" value="Data Export"  color="#C81C67" /></router-link>

          <!-- <a href="javascript:;" data-toggle="modal" data-target="#choose-pickl-type-popup"><Card icon="Dashboard/create_pickl.png" value="Data Export"  color="#C81C67" /></a> -->
        </div>
        <div class="col-sm-4 col-xs-12" >
          <h3 style="margin-bottom: 20px;margin-left:15px;font-size:150%;">Data Visuals</h3>
          <router-link to="/brand/insight-custom" v-if="$store.state.userData.brandId == 240"><Card icon="Dashboard/brandpayment_icon.png" value="Campaign Summary"  color="#d36b00" /></router-link>
          <router-link to="/brand/campaigns" v-else><Card icon="Dashboard/brandpayment_icon.png" value="Campaigns"  color="#d36b00" /></router-link>
          <router-link to="/brand/insights"><Card icon="Dashboard/viewhostory_icon.png" value=" Insights "  color="#cb4f40" /></router-link>
        </div>
        <div class="row" style="padding:0px 15% 0px 0px">
            <img class="dashboard-hello-image" src="/img/hello-updated.png"/>
        </div>
        <choose-pickl-type-popup :brandId="$store.state.userData.brandId" :popupId="'choose-pickl-type-popup'"/>
        <ExportPickl :popupId="'export-pickl-popup'" :brandId="brandId"/>

    </div>
</template>

<script>
import Card from '@/components/common/Card.vue'
import CreatePicklPopup from '@/components/common/CreatePicklPage.vue'
import ChoosePicklTypePopup from '@/components/common/ChoosePicklTypePopup.vue'
import ExportPickl from '@/components/common/ExportPickl.vue'

export default {
  data () {
    return {
      dashboardContent: {},
      busy: true,
      brandId: 0
    }
  },
  name: 'BrandDashboard',
  components: {
    Card,
    CreatePicklPopup,
    ChoosePicklTypePopup,
    ExportPickl
  },
  methods: {
    getBrandDashboard () {
      this.busy = true
      this.$http
        .get('brands/' + this.brandId + '/dashboard')
        .then(
          function (response) {
            this.dashboardContent = response.body.data
            this.busy = false
          },
          function (response) {
            this.errorMessage = response.body.message
            this.busy = false
          }
        )
    }
  },
  computed: {
    contactPersonName () {
      let name = this.dashboardContent.contact_person_name
      if (name) {
        let index = name.indexOf(' ')
        if (index > -1) {
          name = name.substring(0, index)
        }
      } else {
        name = 'PICKL'
      }
      return name
    }
  },
  mounted () {
    this.getBrandDashboard()
  },
  created () {
    this.brandId = this.$store.state.userData.brandId
    this.onBoarded = this.$store.state.userData.onBoarded
    console.log(this.$store.state.userData)
  }
}
</script>

<style scoped>
h2 {
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 15px;
    font-weight: 700;
    color: black;
    letter-spacing: 1px;
    margin-top: 0;
}

.emoticon {
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
    h2 {
        font-size: 29px;
        line-height: 45px;
    }
}
.dashboard-hello-image {
    width: 60%;

    position: fixed;
    bottom: 0px;
    left: 0;
    margin-left: 23%;
}
div.card {
  max-width: 80%;
}
</style>
