var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticStyle: { "margin-bottom": "40px" } }, [
        _vm._v("Bam! It’s "),
        _c("span", { staticClass: "green-text" }, [_vm._v("PICKL")]),
        _vm._v(" Time. GOOOOO " + _vm._s(_vm.contactPersonName) + "!")
      ]),
      _c("div", { staticClass: "col-xs-12 col-sm-4" }, [
        _c(
          "a",
          {
            attrs: {
              href: "javascript:;",
              "data-toggle": "modal",
              "data-target": "#choose-pickl-type-popup"
            }
          },
          [
            _c("Card", {
              attrs: {
                icon: "Dashboard/create_pickl.png",
                value: "Build Campaign",
                color: "#782b54"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-4" },
        [
          _c(
            "router-link",
            { attrs: { to: "/brand/payment-history?tab=subscription" } },
            [
              _c("Card", {
                attrs: {
                  icon: "Dashboard/brandpayment_icon.png",
                  value: "Credits: " + _vm.dashboardContent.credits,
                  color: "#d36b00"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-4" },
        [
          _c(
            "router-link",
            { attrs: { to: "/brand/tutorial-videos" } },
            [
              _c("Card", {
                attrs: {
                  icon: "Dashboard/viewhostory_icon.png",
                  value: "Tutorials",
                  color: "#cb4f40"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-4 col-xs-12" },
        [
          _c(
            "h3",
            {
              staticStyle: {
                "margin-bottom": "20px",
                "margin-left": "15px",
                "font-size": "150%"
              }
            },
            [_vm._v("Geo-Located Activities")]
          ),
          _c(
            "router-link",
            { attrs: { to: "/brand/pickl-history?status=APPROVED" } },
            [
              _c("Card", {
                attrs: {
                  icon: "Dashboard/newpickls_icon.png",
                  value: "Fresh Alerts",
                  color: "#12db6d"
                }
              })
            ],
            1
          ),
          _c(
            "a",
            {
              staticStyle: { cursor: "pointer" },
              attrs: {
                "data-target": "#export-pickl-popup",
                "data-toggle": "modal"
              }
            },
            [
              _c("Card", {
                attrs: {
                  icon: "Dashboard/create_pickl.png",
                  value: "Data Export",
                  color: "#C81C67"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-4 col-xs-12" },
        [
          _c(
            "h3",
            {
              staticStyle: {
                "margin-bottom": "20px",
                "margin-left": "15px",
                "font-size": "150%"
              }
            },
            [_vm._v("Go-Anywhere Activities")]
          ),
          _c(
            "router-link",
            { attrs: { to: "/brand/non-geo-pickl-history?status=APPROVED" } },
            [
              _c("Card", {
                attrs: {
                  icon: "Dashboard/newpickls_icon.png",
                  value: "Fresh Alerts",
                  color: "#12db6d"
                }
              })
            ],
            1
          ),
          _c(
            "router-link",
            { attrs: { to: "/brand/non-geo-pickl-history?status=APPROVED" } },
            [
              _c("Card", {
                attrs: {
                  icon: "Dashboard/create_pickl.png",
                  value: "Data Export",
                  color: "#C81C67"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-4 col-xs-12" },
        [
          _c(
            "h3",
            {
              staticStyle: {
                "margin-bottom": "20px",
                "margin-left": "15px",
                "font-size": "150%"
              }
            },
            [_vm._v("Data Visuals")]
          ),
          _vm.$store.state.userData.brandId == 240
            ? _c(
                "router-link",
                { attrs: { to: "/brand/insight-custom" } },
                [
                  _c("Card", {
                    attrs: {
                      icon: "Dashboard/brandpayment_icon.png",
                      value: "Campaign Summary",
                      color: "#d36b00"
                    }
                  })
                ],
                1
              )
            : _c(
                "router-link",
                { attrs: { to: "/brand/campaigns" } },
                [
                  _c("Card", {
                    attrs: {
                      icon: "Dashboard/brandpayment_icon.png",
                      value: "Campaigns",
                      color: "#d36b00"
                    }
                  })
                ],
                1
              ),
          _c(
            "router-link",
            { attrs: { to: "/brand/insights" } },
            [
              _c("Card", {
                attrs: {
                  icon: "Dashboard/viewhostory_icon.png",
                  value: " Insights ",
                  color: "#cb4f40"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._m(0),
      _c("choose-pickl-type-popup", {
        attrs: {
          brandId: _vm.$store.state.userData.brandId,
          popupId: "choose-pickl-type-popup"
        }
      }),
      _c("ExportPickl", {
        attrs: { popupId: "export-pickl-popup", brandId: _vm.brandId }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row", staticStyle: { padding: "0px 15% 0px 0px" } },
      [
        _c("img", {
          staticClass: "dashboard-hello-image",
          attrs: { src: "/img/hello-updated.png" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }